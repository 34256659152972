import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import AutohubServices from "../../services/autohubServices";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import classNames from "classnames";
import { Accordion, Button } from "react-bootstrap";
import CheckboxTree from "react-checkbox-tree";
import {
  carModelYears as carModelYearsList,
  offerTypes,
} from "../../util/utils";
import moment from "moment";

export class ServiceForm extends Component {
  constructor() {
    super();
    this.formik = createRef();
  }

  state = {
    suggestion: [],
    data: {},
    carsPanel: false,
    allCarsTree: [],
    carsTree: [],
    expandedTree: [],
    serviceCategories: [],
    loading: true,
    value: {},
  };

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );

      this.setUpForm();
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async setUpForm() {
    const lang = appState.state.language;
    const cars = await AutohubServices.getCarsListing();
    const allCarModels = [];
    const carsTree = cars.map(({ brand, models }) => ({
      value: brand.en,
      label: brand[lang],
      children: models.map((m) => {
        const model = {
          value: m.en,
          label: m[lang],
        };

        allCarModels.push(model);
        return model;
      }),
    }));
    const allCarsTree = [
      {
        value: "all",
        label: "ALL BRANDS AND MODELS",
        children: allCarModels,
      },
    ];

    const serviceCategories = await AutohubServices.getServiceCategories();

    // Setting up for updating the service
    let updatingState = {};
    if (this.props.match.params.id) {
      const service = await AutohubServices.getServiceById(
        this.props.match.params.id,
        { progressBar: true }
      );
      updatingState = {
        start_date: service.startDate ? moment(service.startDate) : "",
        end_date: service.endDate ? moment(service.endDate) : "",
        data: service,
      };
    }

    this.setState({
      ...updatingState,
      serviceCategories,
      carsTree,
      allCarsTree,
      loading: false,
    });
  }

  handleCarModelYearCheck({ target }, { values, setFieldValue }) {
    const { value, checked } = target;
    const modelYearsValue = values.carModelYears || [];
    checked
      ? modelYearsValue.push(value)
      : modelYearsValue.splice(modelYearsValue.indexOf(value), 1);

    setFieldValue("carModelYears", modelYearsValue);
  }

  async handleSubmit(values, { setSubmitting, setErrors }) {
    setSubmitting(true);
    this.setState({ loading: true });

    try {
      const { carModels, carModelYears, ...rest } = values;
      const cars = { models: carModels, years: carModelYears };

      const price = values?.price;
      const total =
        Number(values?.labour || 0) +
        Number(values?.parts || 0) +
        Number(values?.paint || 0) +
        Number(values?.oil || 0) +
        Number(values?.boughtOut || 0) +
        Number(values?.sublet || 0);

      if (Number(total) !== price) {
        return setErrors({
          ...this.formik.errors,
          amountExceeded: this.props.intl.formatMessage({
            id: "offer.amountExceededMessage",
            defaultMessage:
              "Sum of Labour, Parts, Bought Out, Paint, Oil and Sublet can't be greater or less than the Price",
          }),
        });
      }

      let response;
      const payload = {
        ...rest,
        startDate: values.datePicker.startDate || "",
        endDate: values.datePicker.endDate || "",
        englishServiceDetail: values.englishServiceDetail,
        arabicServiceDetail: values.arabicServiceDetail,
        terms_en: values["terms_en"],
        terms_ar: values["terms_ar"],
        cars,
      };
      if (!this.props.match.params.id) {
        response = await AutohubServices.addService(payload);
      } else {
        const request = { id: this.props.match.params.id, ...payload };
        response = await AutohubServices.updateService(request);
      }

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );

      this.props.navigate("/services");
    } catch (e) {
      if (e.response.data.response === "error.duplicateService") {
        return NotificationManager.error(
          this.props.intl.formatMessage({
            id: e.response.data.response,
            defaultMessage: "Service already exist with the same name",
          }),
          this.props.intl.formatMessage({
            id: "global.error",
            defaultMessage: "Error",
          })
        );
      }

      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  handleOfferTypeChange(e, setFieldValue) {
    const value = e.target.value;
    setFieldValue("offerType", value);

    if (value === "nonOffer") {
      setFieldValue("terms_en", "");
      setFieldValue("terms_ar", "");
      setFieldValue("price", 0);
      setFieldValue("oldPrice", 0);
      setFieldValue("labour", 0);
      setFieldValue("parts", 0);
      setFieldValue("boughtOut", 0);
      setFieldValue("paint", 0);
      setFieldValue("oil", 0);
      setFieldValue("sublet", 0);
      setFieldValue("datePicker", { startDate: "", endDate: "" });
      setFieldValue("banner_en", "");
      setFieldValue("banner_ar", "");
    }
  }

  render() {
    const { intl, noLayout, authContext, navigate, match } = this.props;
    const { data } = this.state;

    const carModels = [];
    const carModelYears = [];
    if (data.cars?.length)
      data.cars.forEach(({ product, modelYear }) => {
        carModels.push(product.value_en);
        carModelYears.push(modelYear);
      });

    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            englishName: data.englishName || "",
            arabicName: data.arabicName || "",
            englishServiceDetail: data.detailEnglish || "",
            arabicServiceDetail: data.detailArabic || "",
            terms_en: data?.terms_en || "",
            terms_ar: data?.terms_ar || "",
            price: data.price || 0,
            oldPrice: data.oldPrice || 0,
            labour: data.labour || 0,
            parts: data.parts || 0,
            boughtOut: data.boughtOut || 0,
            paint: data.paint || 0,
            oil: data.oil || 0,
            sublet: data.sublet || 0,
            offerType: data.offerType || "",
            datePicker: data.startDate
              ? {
                  startDate: moment(data.startDate),
                  endDate: moment(data.endDate),
                }
              : {},
            image_en: data.image?.en || "",
            image_ar: data.image?.ar || "",
            banner_en: data.banner?.en || "",
            banner_ar: data.banner?.en || "",
            category: data.category?._id,
            isNewDeal: data.isNewDeal || "",
            englishBtnText: data.buttonText?.en || "",
            arabicBtnText: data.buttonText?.ar || "",
            serviceCode: data.serviceCode || "",
            route: data.route || "",
            isShowInAppointment: data.isShowInAppointment || false,
            isShowInService: data.isShowInService || data.isComingSoon || false,
            isActive: data.isActive || false,
            isUploadImages: data.isUploadImages || false,
            isExpressAllowed: data.isExpressAllowed || false,
            isComingSoon: data.isComingSoon || false,
            carModels: [...new Set(carModels)] || [],
            carModelYears: [...new Set(carModelYears)] || [],
            bg_image_en: data.bgImage?.en || "",
            bg_image_ar: data.bgImage?.ar || "",
            advanceBookingDays: data?.advanceBookingDays || 0,
            isCAD: data?.isCAD || false,
            hideFromWeb: data?.hideFromWeb || false,
            isPopular: data?.isPopular || false,
          }}
          validationSchema={Yup.object().shape(
            {
              englishName: Yup.string()
                .hasNoArabic(
                  intl.formatMessage({
                    id: "error.arabicCharactersAreNotAllowedInThisField",
                    defaultMessage:
                      "Arabic characters are not allowed in this field",
                  })
                )
                .label(
                  intl.formatMessage({
                    id: "service.englishName",
                    defaultMessage: "Service Name (English)",
                  })
                )
                .trim()
                .required(),
              arabicName: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "service.arsabicName",
                    defaultMessage: "Service Name (Arabic)",
                  })
                )
                .trim()
                .required()
                .hasNoEnglish(
                  intl.formatMessage({
                    id: "error.englishCharactersAreNotAllowedInThisField",
                    defaultMessage:
                      "English characters are not allowed in this field",
                  })
                ),
              offerType: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "service.offerType",
                    defaultMessage: "Service Type",
                  })
                )
                .required(),
              englishServiceDetail: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "service.englishServiceDetail",
                    defaultMessage: "Service Detail (English)",
                  })
                )
                .trim()
                .required(),
              arabicServiceDetail: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "service.arabicServiceDetail",
                    defaultMessage: "Service Detail (Arabic)",
                  })
                )
                .trim()
                .required(),
              terms_en: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "service.termsEn",
                    defaultMessage: "Terms & Conditions (English)",
                  })
                )
                .trim()
                .when("offerType", {
                  is: (val) => val !== "nonOffer" && val !== "prePaid",
                  then: (schema) => schema.required(),
                }),
              terms_ar: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "service.termsAr",
                    defaultMessage: "Terms & Conditions (Arabic)",
                  })
                )
                .trim()
                .when("offerType", {
                  is: (val) => val !== "nonOffer" && val !== "prePaid",
                  then: (schema) => schema.required(),
                }),
              oldPrice: Yup.number()
                .label(
                  intl.formatMessage({
                    id: "service.oldPrice",
                    defaultMessage: "Old Price",
                  })
                )
                .when("offerType", {
                  is: (val) => val !== "nonOffer" && val !== "prePaid",
                  then: (schema) =>
                    schema.min(
                      1,
                      intl.formatMessage({
                        id: "service.priceNotZero",
                        defaultMessage:
                          "Should be greater than 0 for paid offer",
                      })
                    ),
                })
                .required(),
              price: Yup.number()
                .label(
                  intl.formatMessage({
                    id: "service.price",
                    defaultMessage: "Price",
                  })
                )
                .when("offerType", {
                  is: (val) => val !== "nonOffer",
                  then: (schema) =>
                    schema.min(
                      1,
                      intl.formatMessage({
                        id: "service.priceNotZero",
                        defaultMessage:
                          "Should be greater than 0 for paid offer",
                      })
                    ),
                })
                .required(),
              labour: Yup.number()
                .label(
                  intl.formatMessage({
                    id: "service.labour",
                    defaultMessage: "Labour",
                  })
                )
                .required(),
              parts: Yup.number()
                .label(
                  intl.formatMessage({
                    id: "service.parts",
                    defaultMessage: "Parts",
                  })
                )
                .required(),
              boughtOut: Yup.number()
                .label(
                  intl.formatMessage({
                    id: "service.boughtOut",
                    defaultMessage: "Bought Out",
                  })
                )
                .required(),
              paint: Yup.number()
                .label(
                  intl.formatMessage({
                    id: "service.paint",
                    defaultMessage: "Paint",
                  })
                )
                .required(),
              oil: Yup.number()
                .label(
                  intl.formatMessage({
                    id: "service.oil",
                    defaultMessage: "Oil",
                  })
                )
                .required(),
              sublet: Yup.number()
                .label(
                  intl.formatMessage({
                    id: "service.sublet",
                    defaultMessage: "Sublet",
                  })
                )
                .required(),
              datePicker: Yup.object().when("offerType", {
                is: (val) => val !== "nonOffer",
                then: (schema) =>
                  schema.test(
                    "duration-check",
                    "Offer Duration is Required",
                    function (value) {
                      const { startDate, endDate } = value;
                      return startDate && endDate;
                    }
                  ),
              }),
              image_en: Yup.mixed()
                .label(
                  intl.formatMessage({
                    id: "service.image_en",
                    defaultMessage: "Thumbnail",
                  })
                )
                .required(),
              image_ar: Yup.mixed()
                .label(
                  intl.formatMessage({
                    id: "service.image_ar",
                    defaultMessage: "Thumbnail",
                  })
                )
                .required(),
              banner_en: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "service.banner_en",
                    defaultMessage: "Banner (English)",
                  })
                )
                .when("offerType", {
                  is: (val) => val !== "nonOffer" && val !== "prePaid",
                  then: (schema) => schema.required(),
                }),
              banner_ar: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "service.banner_ar",
                    defaultMessage: "Banner (Arabic)",
                  })
                )
                .when("offerType", {
                  is: (val) => val !== "nonOffer" && val !== "prePaid",
                  then: (schema) => schema.required(),
                }),
              category: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "service.category",
                    defaultMessage: "Service Category",
                  })
                )
                .required(),
              route: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "service.slug",
                    defaultMessage: "Slug",
                  })
                )
                .trim(),
              englishBtnText: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "service.englishBtnText",
                    defaultMessage: "English button text",
                  })
                )
                .trim()
                .hasNoArabic(
                  intl.formatMessage({
                    id: "error.arabicCharactersAreNotAllowedInThisField",
                    defaultMessage:
                      "Arabic characters are not allowed in this field",
                  })
                )
                .when("arabicBtnText", {
                  is: (arabicBtnText) => arabicBtnText,
                  then: (schema) => schema.required(),
                }),
              arabicBtnText: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "service.arabicBtnText",
                    defaultMessage: "Arabic button text",
                  })
                )
                .trim()
                .hasNoEnglish(
                  intl.formatMessage({
                    id: "error.englishCharactersAreNotAllowedInThisField",
                    defaultMessage:
                      "English characters are not allowed in this field",
                  })
                )
                .when("englishBtnText", {
                  is: (englishBtnText) => englishBtnText,
                  then: (schema) => schema.required(),
                }),
              serviceCode: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "service.serviceCode",
                    defaultMessage: "Branch Code",
                  })
                )
                .trim()
                .required(),
              carModels: Yup.array().of(Yup.string()),
              carModelYears: Yup.array().when("carModels", {
                is: (carModels) => carModels?.length,
                then: (schema) => schema.min(1).of(Yup.string().required()),
              }),
              advanceBookingDays: Yup.number()
                .label(
                  intl.formatMessage({
                    id: "service.advanceBookingDays",
                    defaultMessage: "Advance Booking Days",
                  })
                )
                .min(0)
                .required(),
              isComingSoon: Yup.boolean(),
              bg_image_en: Yup.mixed().when("isComingSoon", {
                is: (val) => val,
                then: (schema) =>
                  schema
                    .label(
                      intl.formatMessage({
                        id: "service.imageBgAr",
                        defaultMessage: "Bg Image (English)",
                      })
                    )
                    .required(),
              }),
              bg_image_ar: Yup.mixed().when("isComingSoon", {
                is: (val) => val,
                then: (schema) =>
                  schema
                    .label(
                      intl.formatMessage({
                        id: "service.imageBgAr",
                        defaultMessage: "Bg Image (Arabic)",
                      })
                    )
                    .required(),
              }),
            },
            [
              "englishBtnText",
              "arabicBtnText",
              "isComingSoon",
              "bg_image_en",
              "bg_image_ar",
            ]
          )}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {(formikProps) => {
            const lang = appState.state.language;
            const { carsPanel, allCarsTree, carsTree, expandedTree } =
              this.state;
            const { values, setFieldValue, isSubmitting, errors, touched } =
              formikProps;

            return (
              <Layout noLayout={noLayout} loading={this.state.loading}>
                <HeaderTitle id="service.service" defaultMessage="Service" />
                <div className="dashboard-table-container card-layout">
                  <div className="panel-primary">
                    <header className="panel-title">
                      {match.params.id && (
                        <FormattedMessage
                          id="service.updateService"
                          defaultMessage="Update Service"
                        />
                      )}
                      {!match.params.id && (
                        <FormattedMessage
                          id="service.addService"
                          defaultMessage="Add Service"
                        />
                      )}
                    </header>

                    <Form>
                      <div className="dashboard-row">
                        <FieldRow
                          type="text"
                          name="englishName"
                          labelId="service.englishNameLabel"
                          defaultLabel="Service Name (English)"
                          placeholderId="service.enterEnglishName"
                          defaultPlaceholder="Enter english name"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                        />

                        <FieldRow
                          type="text"
                          name="arabicName"
                          labelId="service.arabicNameLabel"
                          defaultLabel="Service Name (Arabic)"
                          placeholderId="service.enterArabicName"
                          defaultPlaceholder="Enter arabic name"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                        />

                        <FieldRow
                          type="select"
                          name="offerType"
                          labelId="offer.offerType"
                          defaultLabel="Service Type"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                          onChange={(e) =>
                            this.handleOfferTypeChange(e, setFieldValue)
                          }
                        >
                          <option value="">
                            {intl.formatMessage({
                              id: "offer.selectOfferType",
                              defaultMessage: "Select Service Type",
                            })}
                          </option>
                          {offerTypes.map((offer, index) => {
                            return (
                              <option value={offer?.value} key={index}>
                                {offer?.["label_" + lang]}
                              </option>
                            );
                          })}
                        </FieldRow>

                        <FieldRow
                          type="html"
                          name="englishServiceDetail"
                          labelId="service.englishDetails"
                          defaultLabel="Enter service detail (English)"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                          textEditor={true}
                        />

                        <FieldRow
                          type="html"
                          name="arabicServiceDetail"
                          labelId="service.arabicDetail"
                          defaultLabel="Enter service detail (Arabic)"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                          textEditor={true}
                        />

                        {values.offerType !== "nonOffer" ||
                          (values.offerType !== "prePaid" && (
                            <>
                              <FieldRow
                                type="html"
                                name="terms_en"
                                labelId="offer.terms_en"
                                defaultLabel="Terms and Conditions (English)"
                                showRequiredFlag={true}
                                formikProps={formikProps}
                                textEditor={true}
                              />
                              <FieldRow
                                type="html"
                                name="terms_ar"
                                labelId="offer.terms_ar"
                                defaultLabel="Terms and Conditions (Arabic)"
                                showRequiredFlag={true}
                                formikProps={formikProps}
                                textEditor={true}
                              />
                            </>
                          ))}

                        {values.offerType !== "nonOffer" && (
                          <>
                            <div className="date-picker">
                              <FieldRow
                                type="date"
                                name="datePicker"
                                labelId="offer.campaignDate"
                                defaultLabel="Offer Active Duration"
                                showRequiredFlag={true}
                                formikProps={formikProps}
                                disabled={false}
                                data={values.datePicker}
                                initialStartDate={values.datePicker.startDate}
                                initialEndDate={values.datePicker.endDate}
                              />
                            </div>

                            <hr />
                            {values?.offerType !== "prePaid" && (
                              <FieldRow
                                type="number"
                                name="oldPrice"
                                labelId="offer.oldPrice"
                                defaultLabel="Old Price"
                                placeholderId="offer.oldPrice"
                                defaultPlaceholder="Old Price"
                                showRequiredFlag={true}
                                formikProps={formikProps}
                              />
                            )}
                            <FieldRow
                              type="number"
                              name="price"
                              labelId="offer.price"
                              defaultLabel="Price"
                              placeholderId="offer.price"
                              defaultPlaceholder="Price"
                              showRequiredFlag={true}
                              formikProps={formikProps}
                            />
                            <FieldRow
                              type="number"
                              name="labour"
                              labelId="offer.labour"
                              defaultLabel="Labour"
                              placeholderId="offer.labour"
                              defaultPlaceholder="Labour"
                              formikProps={formikProps}
                              showRequiredFlag={true}
                            />
                            <FieldRow
                              formikProps={formikProps}
                              type="number"
                              name="parts"
                              labelId="offer.parts"
                              defaultLabel="Parts"
                              placeholderId="offer.parts"
                              defaultPlaceholder="Parts"
                            />
                            <FieldRow
                              formikProps={formikProps}
                              type="number"
                              name="boughtOut"
                              labelId="offer.boughtOut"
                              defaultLabel="Bought Out"
                              placeholderId="offer.boughtOut"
                              defaultPlaceholder="Bought Out"
                            />
                            <FieldRow
                              formikProps={formikProps}
                              type="number"
                              name="paint"
                              labelId="offer.paint"
                              defaultLabel="Paint"
                              placeholderId="offer.paint"
                              defaultPlaceholder="Paint"
                            />
                            <FieldRow
                              formikProps={formikProps}
                              type="number"
                              name="oil"
                              labelId="offer.oil"
                              defaultLabel="Oil"
                              placeholderId="offer.oil"
                              defaultPlaceholder="Oil"
                            />
                            <FieldRow
                              formikProps={formikProps}
                              type="number"
                              name="sublet"
                              labelId="offer.sublet"
                              defaultLabel="Sublet"
                              placeholderId="offer.sublet"
                              defaultPlaceholder="Sublet"
                            />

                            {errors && errors["amountExceeded"] && (
                              <span className="text-warning">
                                {errors["amountExceeded"]}
                              </span>
                            )}

                            <hr />
                          </>
                        )}

                        <FieldRow
                          type="file"
                          name="image_en"
                          image={true}
                          aspectRatio={325.8 / 325}
                          imageSize="W:325.8 x H:325"
                          cameraIcon={true}
                          showThumbnail={true}
                          labelId="service.imageEn"
                          defaultLabel="Select Image (English)"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                          disabled={false}
                        />

                        <FieldRow
                          type="file"
                          name="image_ar"
                          image={true}
                          aspectRatio={325.8 / 325}
                          imageSize="W:325.8 x H:325"
                          cameraIcon={true}
                          showThumbnail={true}
                          labelId="service.imageAr"
                          defaultLabel="Select Image (Arabic)"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                          disabled={false}
                        />

                        {values.offerType !== "nonOffer" ||
                          (values.offerType !== "prePaid" && (
                            <>
                              <FieldRow
                                name="banner_en"
                                disabled={false}
                                cameraIcon={true}
                                type="file"
                                image={true}
                                showThumbnail={true}
                                labelId="offer.banner_en"
                                defaultLabel="Banner En"
                                placeholderId="offer.banner_en"
                                defaultPlaceholder="Offer Banner"
                                showRequiredFlag={true}
                                formikProps={formikProps}
                                aspectRatio={1130 / 450}
                                imageSize="W:1130 x H:450"
                                forServices={true}
                              />
                              <FieldRow
                                name="banner_ar"
                                disabled={false}
                                cameraIcon={true}
                                type="file"
                                image={true}
                                showThumbnail={true}
                                labelId="offer.banner_ar"
                                defaultLabel="Banner Ar"
                                placeholderId="offer.banner_ar"
                                defaultPlaceholder="Offer Banner"
                                showRequiredFlag={true}
                                formikProps={formikProps}
                                aspectRatio={1130 / 450}
                                imageSize="W:1130 x H:450"
                                forServices={true}
                              />
                            </>
                          ))}

                        <FieldRow
                          type="select"
                          name="category"
                          labelId="service.category"
                          defaultLabel="Category"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                        >
                          <option value="">
                            {intl.formatMessage({
                              id: "service.category",
                              defaultMessage: "Select Category",
                            })}
                          </option>
                          {this.state.serviceCategories?.map(
                            (serviceCategory) => {
                              return (
                                <option
                                  value={serviceCategory?._id}
                                  key={serviceCategory?._id}
                                >
                                  {serviceCategory?.name["value_" + lang]}
                                </option>
                              );
                            }
                          )}
                        </FieldRow>

                        <FieldRow
                          type="text"
                          name="serviceCode"
                          id="serviceCode"
                          labelId="service.serviceCode"
                          defaultLabel="Branch Code"
                          placeholderId="service.enterBranchCode"
                          defaultPlaceholder="Enter Branch Code"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                        />

                        <FieldRow
                          name="englishBtnText"
                          labelId="service.englishBtnText"
                          defaultLabel="Button Text (English)"
                          placeholderId="service.enterenglishBtnText"
                          defaultPlaceholder="Enter button text (English)"
                          formikProps={formikProps}
                          disabled={false}
                        />

                        <FieldRow
                          name="arabicBtnText"
                          labelId="service.arabicBtnText"
                          defaultLabel="Button Text (Arabic)"
                          placeholderId="service.enterArabicBtnText"
                          defaultPlaceholder="Enter button text (Arabic)"
                          formikProps={formikProps}
                          disabled={false}
                        />

                        <FieldRow
                          type="text"
                          name="route"
                          labelId="service.slug"
                          defaultLabel="BUTTON LINK (E.G. /PAGENAME)"
                          placeholderId="service.enterButtonLink"
                          defaultPlaceholder="Enter button link"
                          formikProps={formikProps}
                          disabled={values.isComingSoon}
                        />

                        <FieldRow
                          type="checkbox"
                          name="isShowInAppointment"
                          labelId="service.isShowInAppointment"
                          defaultLabel="DISPLAY IN SCHEDULE APPOINTMENT"
                          formikProps={formikProps}
                          disabled={values.isComingSoon}
                        />

                        <FieldRow
                          type="checkbox"
                          name="isShowInService"
                          labelId="service.isShowInService"
                          defaultLabel="DISPLAY IN SERVICE"
                          formikProps={formikProps}
                          disabled={false}
                        />

                        <FieldRow
                          type="checkbox"
                          name="isActive"
                          labelId="service.isActive"
                          defaultLabel="Active"
                          formikProps={formikProps}
                          showRequiredFlag={false}
                        />

                        <FieldRow
                          type="checkbox"
                          name="isUploadImages"
                          labelId="service.isUploadImages"
                          defaultLabel="Upload Images"
                          formikProps={formikProps}
                          showRequiredFlag={false}
                        />

                        <FieldRow
                          type="checkbox"
                          name="isExpressAllowed"
                          labelId="service.isExpressAllowed"
                          defaultLabel="Express Allowed"
                          formikProps={formikProps}
                          showRequiredFlag={false}
                        />

                        <FieldRow
                          type="checkbox"
                          name="isComingSoon"
                          labelId="service.isComingSoon"
                          defaultLabel="Coming Soon"
                          formikProps={formikProps}
                          showRequiredFlag={false}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            setFieldValue("isComingSoon", checked);
                            setFieldValue("isShowInService", checked);
                            if (checked) setFieldValue("route", "");
                          }}
                        />

                        {values?.isComingSoon ? (
                          <>
                            <FieldRow
                              type="file"
                              name="bg_image_en"
                              image={true}
                              aspectRatio={325.8 / 325}
                              imageSize="W:325.8 x H:325"
                              cameraIcon={true}
                              showThumbnail={true}
                              labelId="service.imageBgEn"
                              defaultLabel="Select Background Image (English)"
                              showRequiredFlag={
                                values?.isComingSoon ? true : false
                              }
                              formikProps={formikProps}
                              disabled={false}
                            />

                            <FieldRow
                              type="file"
                              name="bg_image_ar"
                              image={true}
                              aspectRatio={325.8 / 325}
                              imageSize="W:325.8 x H:325"
                              cameraIcon={true}
                              showThumbnail={true}
                              labelId="service.imageBgAr"
                              defaultLabel="Select Background  Image (Arabic)"
                              showRequiredFlag={
                                values?.isComingSoon ? true : false
                              }
                              formikProps={formikProps}
                              disabled={false}
                            />
                          </>
                        ) : null}

                        <FieldRow
                          type="checkbox"
                          name="isNewDeal"
                          labelId="service.newDeal"
                          defaultLabel="New Deal"
                          formikProps={formikProps}
                        />

                        {values?.price > 0 && (
                          <FieldRow
                            type="checkbox"
                            name="isCAD"
                            labelId="service.isCAD"
                            defaultLabel="Cash And Delivery"
                            formikProps={formikProps}
                          />
                        )}

                        <FieldRow
                          type="checkbox"
                          name="hideFromWeb"
                          labelId="service.hideFromWeb"
                          defaultLabel="Hide from Web"
                          formikProps={formikProps}
                          showRequiredFlag={false}
                        />

                        {values?.offerType !== "nonOffer" && (
                          <FieldRow
                            type="checkbox"
                            name="isPopular"
                            labelId="service.isPopular"
                            defaultLabel="Is Popular"
                            formikProps={formikProps}
                            showRequiredFlag={false}
                          />
                        )}

                        <FieldRow
                          type="number"
                          name="advanceBookingDays"
                          labelId="service.advanceBookingDays"
                          defaultLabel="Advance booking days"
                          placeholderId="service.advanceBookingDays"
                          defaultPlaceholder="Enter advance booking days"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                          disabled={false}
                        />

                        <br />

                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  errors.carModelYears && touched.carModelYears
                                    ? "red"
                                    : "#555",
                              }}
                              onClick={() =>
                                this.setState({ carsPanel: !carsPanel })
                              }
                            >
                              <FormattedMessage
                                id="service.assignCars"
                                defaultMessage="Assign Cars"
                              />
                              <span
                                className={classNames(
                                  "glyphicon",
                                  "pull-right",
                                  {
                                    "glyphicon-plus": !carsPanel,
                                    "glyphicon-minus": carsPanel,
                                  }
                                )}
                                aria-hidden="true"
                              />
                            </Accordion.Header>

                            <Accordion.Body className="cars-for-service">
                              <CheckboxTree
                                nodes={allCarsTree}
                                checked={values.carModels}
                                onCheck={(selectedModels) => {
                                  setFieldValue("carModels", selectedModels);
                                }}
                                nativeCheckboxes={true}
                                showNodeIcon={false}
                                icons={{ expandClose: null }}
                              />
                              <CheckboxTree
                                nodes={carsTree}
                                checked={values.carModels}
                                onCheck={(selectedModels) => {
                                  setFieldValue("carModels", selectedModels);
                                }}
                                expanded={expandedTree}
                                onExpand={(expandedTree) =>
                                  this.setState({ expandedTree })
                                }
                                nativeCheckboxes={true}
                                showExpandAll={true}
                                showNodeIcon={false}
                              />

                              {values.carModels?.length ? (
                                <div className="car-model-years">
                                  {carModelYearsList.map((year) => {
                                    return (
                                      <label htmlFor={year} key={year}>
                                        <input
                                          type="checkbox"
                                          name="carModelYears"
                                          id={year}
                                          value={year}
                                          onChange={(e) =>
                                            this.handleCarModelYearCheck(
                                              e,
                                              formikProps
                                            )
                                          }
                                          defaultChecked={values.carModelYears.includes(
                                            year
                                          )}
                                        />
                                        <span>{year}</span>
                                      </label>
                                    );
                                  })}
                                </div>
                              ) : null}
                              {errors.carModelYears &&
                                touched.carModelYears && (
                                  <span className="car-model-year-error">
                                    <FormattedMessage
                                      id="service.carModelYearError"
                                      defaultMessage="Please select model years also for selected cars"
                                    />
                                  </span>
                                )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>

                      <div className="dashboard-row -nobottom">
                        {authContext.authorized && (
                          <input
                            type="submit"
                            className="btn btn-primary"
                            value={intl.formatMessage({
                              id: "global.submit",
                              defaultMessage: "Submit",
                            })}
                            disabled={isSubmitting}
                          />
                        )}
                        <Button
                          variant="link"
                          onClick={() => navigate("/services")}
                          disabled={isSubmitting}
                        >
                          {intl.formatMessage({
                            id: "global.cancel",
                            defaultMessage: "Cancel",
                          })}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Layout>
            );
          }}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(ServiceForm)));
